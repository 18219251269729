.blog-title {
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      list-style: none;
      &:not(:last-child) {
        margin-right: 15px;
      }
      font-weight: 500;
      a {
        color: $color-primary;
        &:hover {
          color: $color-secondary;
        }
      }
    }
  }
}

.blog {
  &__pagination {
    display: flex;
    justify-content: center;
    .page-link {
      color: $color-primary;
      &:hover {
        background-color: $color-secondary;
        color: $color-light;
      }
      &:focus {
        box-shadow: none;
        background-color: $color-primary;
        color: $color-light;
      }
    }
    .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      li {
        a {
          border-radius: 50%;
          border: 1px solid $color-primary;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-primary;
          font-size: 16px;
          transition: all 0.3s;
          &:hover {
            background-color: $color-primary;
            color: $color-light;
          }
        }
      }
    }
  }
}
