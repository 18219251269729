.gallery {
  &__menu {
    ul .active {
      background-color: $color-primary;
      color: $color-light;
    }
    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin: 0px;
      padding: 0px;
    }
    ul li {
      padding: 8px 20px;
      border: 1px solid $color-primary;
      color: $color-dark;
      border-radius: 3px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      line-height: 26px;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        background-color: $color-secondary;
        color: $color-light;
        border: 1px solid $color-secondary;
      }
    }
  }
  &__area {
    padding-top: 30px;
  }
  &__item img {
    width: 100%;
    margin-bottom: 22px;
    border-radius: 17px;
    cursor: pointer;
  }
  &-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    &--content {
      h2 {
        margin-bottom: 0px;
        a {
          color: $color-dark;
        }
      }
    }
    p {
      color: $color-gray;
      margin: 0px;
    }
  }
  &__btn {
    border: 1px solid $color-primary;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    background-color: transparent;
    margin-right: 16px;
    color: $color-dark;
    &:hover {
      color: $color-light;
      background-color: $color-secondary;
    }
    i {
      font-size: 25px;
      rotate: 40deg;
    }
  }
  &__thumb {
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
  .modal {
    &-dialog {
      max-width: 1200px;
    }
    &-content {
      border-radius: 0px;
      max-width: fit-content;
      margin: 0 auto;
      box-shadow: 5px 5px var(--secondary);
    }
    &-main {
      position: relative;
      padding: 50px;
      @include respond(tablet) {
        padding: 15px;
      }
    }
  }
  .close-btn {
    border: 1px solid $color-border;
    background-color: $color-dark;
    color: $color-light;
    height: 32px;
    width: 32px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    transition: 0.4s;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0;
    &:hover {
      color: $color-light;
      background-color: $color-secondary;
    }
  }
  .client__meta {
    @include respond(tablet) {
      margin: 5px 0;
    }
    p {
      margin: 0px;
    }
    h5 {
      margin: 0px;
    }
  }
  .hr {
    color: $color-gray;
    margin: 20px 0;
  }
  &__details {
    p {
      margin: 0px;
    }
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      li {
        display: flex;
        gap: 22px;
        margin-top: 10px;
        align-items: center;
        i {
          color: $color-gray;
          font-size: 18px;
          margin-top: 7px;
        }
      }
    }
  }
}
.bg-one.gallery {
  .modal {
    &-content {
      background-color: $bg-one;
    }
  }
}
.modal-content {
  max-width: 750px;
}