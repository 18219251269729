.all-blog2 {
    &__area {
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0px 1px 15px 0px $color-shadow;
        background-color: $color-light;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        &__image {
            margin-bottom: 20px;
            border-radius: 5px;
        }
        &__menu {
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                li {
                    list-style: none;
                    margin-right: 15px;
                }
            }
        }
    }
    &__sidebar {
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 1px 15px 0px $color-shadow;
        background-color: $color-light;
        form {
            border-width: 1px;
            border-style: solid;
            border-color: $color-border;
            display: flex;
            align-items: center;
            border-radius: 5px;
            margin-bottom: 30px;
            input {
                border: none;
                padding: 5px;
                width: 100%;
                &:focus {
                    outline: none;
                }
            }
            i {
                background-color: $color-primary;
                padding: 15px;
                color: $color-light;
                cursor: pointer;
            }
        }
        &__categories {
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    a {
                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
        &__latest-news {
            &__item {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                img {
                    margin-right: 15px;
                }
                &__content {
                    .title {
                        a {
                            &:hover {
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
        }
        &__archive {
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    margin-bottom: 10px;
                    a {
                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
        &__popular-tags {
            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    padding: 5px 10px;
                    border-radius: 10px;
                    background-color: $bg-one;
                    transition: all 0.5s;
                    margin-bottom: 10px;
                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                    &:hover {
                        background-color: $color-primary;
                        a {
                           
                                color: $color-light;
                       
                        }
                    }
                    
                }
            }
        }
        .item-border {
            position: relative;
            border-bottom: 1px solid $color-border;
            padding-bottom: 30px;
            margin-bottom: 30px;
            &::before {
                content: "";
                // background: url(../../images/blog/sidebar-icon.png) no-repeat #fff center;
                width: 16px;
                height: 16px;
                position: absolute;
                left: 50%;
                margin-left: -20px;
                bottom: -8px;
                padding: 0 20px;
            }
        }
    }
}
