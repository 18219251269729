.single-event {
  &__area {
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0px 1px 15px 0px $color-shadow;
    background-color: $color-light;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    &__image {
      margin-bottom: 20px;
      border-radius: 5px;
    }
    &__menu {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        li {
          list-style: none;
          margin-right: 15px;
        }
      }
    }
  }
  &__sidebar {
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 1px 15px 0px $color-shadow;
    background-color: $color-light;
    &__details {
      ul {
        margin: 0;
        padding: 0;
        li {
          &:not(:last-child) {
            margin-bottom: 25px;
          }
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          svg {
            font-size: 30px;
            padding-right: 20px;
            color: $color-primary;
          }
          .para-1 {
            font-size: 20px;
            line-height: 1.2;
            margin: 0;
            color: $color-dark;
          }
          .para-2 {
            font-size: 15px;
            line-height: 1.2;
            margin: 0;
          }
        }
      }
    }
    .item-border {
      position: relative;
      border-bottom: 1px solid $color-border;
      padding-bottom: 30px;
      margin-bottom: 30px;
      &::before {
        content: "";
        // background: url(../../images/blog/sidebar-icon.png) no-repeat #fff center;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 50%;
        margin-left: -20px;
        bottom: -8px;
        padding: 0 20px;
      }
    }
  }
}
