@keyframes preLoader {
  100% {
    top: 100%;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

// Hero animation
@keyframes waves {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
}
@keyframes waves {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
}

@keyframes movecircles {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}

@keyframes upDown {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
// Footer heart

@keyframes footerHeart {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.5);
    color: red;
  }
  50% {
    transform: scale(1);
    color: red;
  }
}
