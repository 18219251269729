.single-blog {
    &__metainfo {
        margin: 0;
        padding: 20px 0;
        display: flex;
        align-items: center;
        li {
            list-style: none;
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-right: 15px;
            }
            svg {
                margin-right: 5px;
                font-size: 16px;
                color: $color-primary;
            }
            a {
                color: $color-dark;
                margin-left: 5px;
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
    &__title {
        margin-bottom: 13px;
    }
    &__para {
        margin-bottom: 25px;
        &__quote {
            position: relative;
            padding: 35px;
            text-align: left;
            margin: 30px 0;
            background-color: $bg-one;
            display: flex;
            align-items: center;
            &__icon {
                width: 70px;
                height: 100px;
                svg {
                    color: $color-primary;
                    font-size: 68px;
                    line-height: .8;
                    opacity: .7;
                }
            }
            &__text {
                width: calc(100% - 100px);
                margin-left: 30px;
                text-align: center;
            }
        }
        ol {
            padding: 20px;
            li {
                padding-left: 3px;
                margin-bottom: 15px;
            }
        }
        .content-feature-list {
            margin-top: 15px;
            margin-bottom: 25px;
            padding: 0;
            li {
                list-style: none;
                margin: 0 0 18px;
                position: relative;
                svg {
                    color: $color-primary;
                    margin-right: 10px;
                }
            }
        }
    }
    &__share {
        display: flex;
        align-items: center;
        gap: 16px;
    }
    &__comment {
        margin-top: 30px;
        &__title {
            margin-bottom: 30px;
            h4 {
                margin: 0;
            }
        }
        &__item {
            padding: 25px;
            margin-bottom: 25px;
            display: flex;
            border-radius: 5px;
            box-shadow: 0px 1px 15px 0px $color-shadow;
            &__content {
                margin-left: 20px;
            }
        }
        .comment-text {
            p {
                margin: 0;
            }
        }
        &__form {
            margin-top: 30px;
            padding: 25px;
            form {
                input,
                textarea {
                width: 100%;
                border: none;
                padding: 10px;
                margin-bottom: 15px;
                background-color: $color-light;
                border-bottom: 2px solid transparent;
                }
                input:focus,
                textarea:focus {
                outline: none;
                border: none;
                border-bottom: 2px solid $color-primary;
                }
                textarea {
                min-height: 120px;
                transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}

.bg-one {
    .single-blog__para__quote,
    .single-blog__share,
    .single-blog__comment__item,
    .single-blog__comment__form {
        background-color: $color-light;
        input,
        textarea {
            background-color: $bg-one;
        }
    }
}